.search-header {
  padding: 1em;
}

.search-button {
  margin-top: 12px;
}

.left {
  float: left;
}

.w-150 {
  width: 150px;
}

.App-icon {
  height: 32px;
}

.App-bd {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: .2rem;
  background: rgb(248,248,248);
}

.tema-container {
  display: flex;
  flex-direction: row;
}

.teknisk-container {
  padding: 2px;
}

.aar-sum {
  /*padding-right: 40px;*/
  margin-top: 5px;
  margin-bottom: 10px;
  width: auto;
  margin-right: 10px;
  /*padding-right: 10px;*/
  /*font-size: medium;*/
}

.aar-sum-col {
  padding-right: 15px;
}

.aar-header {
  font-size: 16px;
  font-weight: bold;
}

.tema-grid {
  vertical-align: top;
  padding-right: 10px;
  column-width: 100%;
}

.spesifisering {
  width: 100%;
}

.tema-container {
  display: flex;
  height: auto;
  flex-shrink: 0;
  flex-basis: content;
  /*flex-wrap: wrap;*/
  align-content: space-between;
  flex-direction: row;
  margin-right: 10px;
}

.temadetaljer-container {
  margin-right: 10px;
}

.header-text {
  padding-left: 1rem;
}

.account-button {
  padding-right: 1rem;
}

.searchField {
  vertical-align: bottom;
  text-align: center;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: #c6538c;
}

.appLayout {
  display : flex;
}

@media only screen and (max-width: 1550px) {
  .appLayout {
    flex-wrap: wrap;
  }
}

.navMenu {
  display : flex;
  flex-direction : column;
  align-content: flex-start;
}

@media only screen and (max-width: 1550px) {
  .navMenu {
    flex-direction : row;
  }
}

.navButton {
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 20px;
  border: 0;
  :focus {
    outline: 0;
    border: 0;
  };
  text-transform: none;
  font-size: 18px !important;
  justify-content: left;
}

.logoutNavButtonDivider {
  width : 150px;
  visibility: visible;
}

@media only screen and (max-width: 1550px) {
  .logoutNavButtonDivider {
    visibility: hidden;
  }
}


.logoutNavButton {
  visibility: visible;
}

@media only screen and (max-width: 1550px) {
  .logoutNavButton {
    visibility: hidden;
  }
}